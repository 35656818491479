.text-area {
  position: relative;
  label {
    padding-bottom: 12px;
  }
  textarea {
    @extend %f-ui-5;
    -webkit-appearance: none;
    border: none;
    outline: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 11px 20px;
    width: 100%;

    box-shadow: inset 0 -2px 0 -1px var(--border-interactive);
    background-color: var(--bg-secondary);
    min-height: 200px;

    &:focus[data-focus-method='key'] {
      border-bottom-color: transparent;
      border-radius: 4px;
      box-shadow: inset 0 0 0 2px var(--text-primary);
    }

    &:focus[data-focus-method='mouse'] {
      box-shadow: inset 0 -2px 0 0 var(--text-primary);
    }
  }
}

.text-area__field {
  position: relative;
}

.text-area__field--error {
  textarea {
    padding-right: 64px;
    box-shadow: inset 0 -2px 0 0 var(--text-error);
  }
}

.text-area__error-icon {
  color: var(--text-error);
  position: absolute;
  top: 11px;
  right: 20px;
}

.text-area--disabled {
  opacity: 0.3;
  pointer-events: none;
}
