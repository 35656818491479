.contact-module {
  color: white;
  background: var(--bg-inverse);
  border-radius: 20px;
  margin-top: 96px;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 96px;

  @include breakpoint('md+') {
    margin-top: 128px;
    padding-bottom: 128px;
  }
  @include breakpoint('lg+') {
    margin-top: 192px;
    padding-top: 128px;
    padding-bottom: 160px;
  }
}

.contact-module__wrapper {
  border-top: 1px solid var(--border-interactive);
  padding-top: 12px;
  @include breakpoint('md+') {
    display: flex;
    justify-content: space-between;
  }
}

.contact-module__content {
  @include breakpoint('md+') {
    @include colspan(6);
    display: flex;
    flex-direction: column;
  }
  h2 {
    @extend %f-heading-5;
    @include breakpoint('lg+') {
      padding-right: 20px;
    }
  }
}

.contact-module__text {
  @extend %f-subhead-2;
  display: block;
  margin-top: 40px;
  @include breakpoint('lg+') {
    margin-top: 80px;
    padding-right: 20px;
  }
}

.contact-module__actions {
  display: block;
  margin-top: 40px;
}

.contact-module__footer {
  @extend %f-ui-4;
  color: var(--text-text-secondary-inverse);
  display: block;
  padding-top: 64px;
  @include breakpoint('md+') {
    margin-top: auto;
  }

  a {
    color: var(--text-text-secondary-inverse);
    @include underline(var(--text-text-secondary-inverse));
    text-decoration: none;
    position: relative;
    &:hover {
      color: var(--text-text-interactive);
      @include underline;
    }
  }
}

.contact-module__media {
  margin-top: 40px;
  @include imageBorder;
  @include breakpoint('md+') {
    @include colspan(6);
    margin-top: 8px;
    border-radius: 8px;
  }

  .image {
    @include aspectRatio(1, 1);
  }
}

.contact-module--no-image {
  .contact-module__media {
    display: none;
  }
}
