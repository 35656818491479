.services-layout {
  @include spacing;
  position: relative;
  .panel-wrapper {
    position: relative;
    top: 0;
  }

  .sticky-panel-wrapper {
    position: sticky;
    overflow: hidden;
    top: var(--nav-height);
    border-radius: 20px;
  }

  .panel {
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
  }

  .panel--acitve {
    overflow: auto;
  }

  .panel-wrapper {
    width: 100%;
    position: relative;
  }

  .panel {
    h3 {
      @extend %f-heading-4;
    }

    .header {
      @extend %f-ui-6;
    }

    &:nth-child(even) {
      background: var(--bg-tertiary);
    }
    &:nth-child(odd) {
      background: var(--bg-secondary);
    }

    .header {
      height: 68px;
      padding: 0 20px;
      span {
        display: block;
        padding-top: 10px;
      }
    }

    .panel-content__wrapper {
      padding: 0 20px;
      position: relative;
    }

    .panel-content__content {
      border-top: 1px solid var(--border-primary);
      @include breakpoint('md+') {
        display: flex;
        justify-content: space-between;
      }

      h3 {
        padding-top: 20px;
      }
    }

    .panel-content__text {
      overflow: hidden;
      @include breakpoint('md+') {
        @include colspan(9);
        padding-right: 20px;
      }
      @include breakpoint('lg+') {
        @include colspan(6);
      }

      .scroller {
        overflow: auto;
        padding-bottom: 40px;
      }

      .rt-module {
        @extend %f-body-2;
        strong {
          @include body2Bold;
        }
        padding-top: 20px;
        @include breakpoint('lg+') {
          padding-top: 40px;
        }
      }
    }

    .panel-content__media {
      display: none;

      > div {
        @include imageBorder;
      }

      @include breakpoint('md+') {
        @include colspan(3);
        padding-top: 20px;
        display: block;
        div {
          @include aspectRatio(1, 1);
        }
      }

      @include breakpoint('lg+') {
        @include colspan(6);
        div {
          @include aspectRatio(8, 5);
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .panel-content__list {
    margin-top: 28px;
    li {
      @extend %f-ui-4;
    }
    @include breakpoint('xl+') {
      margin-top: 32px;
    }
  }
}

.panel-content__link {
  padding-top: 32px;
  padding-bottom: 48px;
  @include breakpoint('lg+') {
    padding-top: 40px;
  }
}

// .global-header--mobile,
// .global-header--desktop {
//   background: pink !important;
// }

// .services-layout {
//   background: red;
// }
