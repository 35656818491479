.list {
  @include spacing;
}

.list--1 {
  @include gridContainer;
  gap: 20px;
  > div {
    @include gridColSpan(12);
  }
}

.list--2 {
  @include gridContainer;
  grid-auto-rows: 1fr;
  gap: 64px var(--inner-gutter);
  @include breakpoint('md+') {
    gap: 80px var(--inner-gutter);
  }
  @include breakpoint('lg+') {
    gap: 96px var(--inner-gutter);
  }
  @include breakpoint('sm-') {
    grid-auto-rows: min-content;
  }

  > div {
    @include gridColSpan(12);
    @include breakpoint('md+') {
      @include gridColSpan(6);
    }
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .card__content {
    flex: 1;
    background: var(--bg-secondary);
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .icon-button {
      flex-shrink: 0;
      align-self: flex-end;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .card__cta {
    display: flex;
    padding-bottom: 20px;
    flex: 1;
    align-items: end;
  }

  .card__cta .icon-button {
    margin-right: 20px;
    margin-left: auto;
  }
}

.list--short-space {
  gap: var(--inner-gutter);
  @include breakpoint('md+') {
    gap: var(--inner-gutter);
  }
  @include breakpoint('lg+') {
    gap: var(--inner-gutter);
  }
}

.list--4 {
  @include gridContainer;
  gap: 64px var(--inner-gutter);
  @include breakpoint('md+') {
    gap: 80px var(--inner-gutter);
  }
  @include breakpoint('lg+') {
    gap: 96px var(--inner-gutter);
  }
  > div {
    @include gridColSpan(12);
    @include breakpoint('md+') {
      @include gridColSpan(6);
    }
    @include breakpoint('lg+') {
      @include gridColSpan(3);
    }
  }
}

.list--equal {
  @include breakpoint('md+') {
    grid-auto-rows: 1fr;
  }
}
