.grid-card__image {
  @include aspectRatio(1, 1);
  @include colspan(6);
  @include imageBorder;
  margin-bottom: 20px;
  overflow: hidden;
  @include breakpoint('md+') {
    @include colspan(3);
  }
  @include breakpoint('lg+') {
    @include colspan(2);
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.grid-card__content {
  @include breakpoint('md+') {
    padding-right: 20px;
  }
}

.grid-card__title {
  @extend %f-ed-heading-1;
  padding-bottom: 12px;
}

.grid-card__description {
  .rt-module > :first-child {
    margin-top: 0px;
  }
  .rt-module {
    @extend %f-body-1;
    strong {
      font-weight: 700;
      letter-spacing: 0.015em;
      font-family: inherit !important;
    }
  }
}

.grid-list {
  @include breakpoint('md+') {
    @include colspan(12);
  }

  @include breakpoint('lg+') {
    @include colspan(6);
  }

  .grid-list__grid {
    @include gridContainer;
    grid-gap: 48px var(--inner-gutter);
    @include breakpoint('md+') {
      grid-gap: 64px var(--inner-gutter);
    }
  }

  .grid-card {
    @include gridColSpan(12);
    @include breakpoint('md+') {
      @include gridColSpan(5);
    }
    @include breakpoint('lg+') {
      @include gridColSpan(3);
    }
  }
}

.block--grid-list {
  @include smallSpacing;
  .container {
    @include breakpoint('lg+') {
      display: flex;
      justify-content: flex-end;
    }
  }
}
