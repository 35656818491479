.themes-card {
  background: var(--aqua-95);
  color: var(--text-text-accent);
  border-radius: 20px;
  padding: 24px 20px 35px 20px;
  display: block;
  text-decoration: none;
  width: 100%;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.4, 0.2, 0, 1);
  will-change: transform;

  &:nth-child(3n + 1) {
  }

  &:nth-child(3n + 2) {
    color: white;
    background: var(--bg-inverse);
  }

  &:nth-child(3n + 3) {
    background: var(--bg-secondary);
    color: var(--text-primary);
  }

  @include breakpoint('sm') {
    padding-bottom: 20px;
  }
  @include breakpoint('md+') {
    padding-bottom: 0px;
  }

  &:hover,
  &:focus {
    @include expandFill;
  }
}

.themes-card_top {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @include breakpoint('md+') {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.themes-card_label {
  @extend %f-ui-1;
  text-transform: uppercase;
}

.themes-card_question {
  padding-top: 6px;
  @extend %f-heading-5;
}

.themes-card_content__primary {
  align-self: flex-start;

  @include breakpoint('md+') {
    max-width: colspan(8);
  }
}

.themes-card_content__secondary {
  align-self: flex-end;
  margin-top: 20px;
}

.themes-card_content__tertiary {
  @extend %f-subhead-1;
  padding-bottom: 80px;
  display: none;
  @include breakpoint('sm+') {
    display: block;
  }
  max-height: 200px;
  overflow: auto;
}
