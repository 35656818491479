@font-face {
  font-family: 'AeonikPro-Regular';
  font-weight: 400;
  src: url('./fonts/AeonikPro-Regular.woff2') format('woff2'),
    url('./fonts/AeonikPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'AeonikPro-Regular';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/AeonikPro-RegularItalic.woff2') format('woff2'),
    url('./fonts/AeonikPro-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'AeonikPro-Medium';
  font-weight: 500;
  src: url('./fonts/AeonikPro-Medium.woff2') format('woff2'),
    url('./fonts/AeonikPro-Medium.woff') format('woff');
}

@font-face {
  font-family: 'AeonikFono-Regular';
  font-weight: 400;
  src: url('./fonts/AeonikFono-Regular.woff2') format('woff2'),
    url('./fonts/AeonikFono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Orbikular-Regular';
  font-weight: 400;
  src: url('./fonts/Orbikular-Regular.woff2') format('woff2'),
    url('./fonts/Orbikular-Regular.woff') format('woff');
}

// @font-face {
//   font-family: 'Orbikular-Regular';
//   font-weight: 600;
//   src: url('./fonts/AeonikPro-Bold.woff2') format('woff2'),
//     url('./fonts/AeonikPro-Bold.woff') format('woff');
// }
