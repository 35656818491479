.link-pill {
  @extend %f-ui-7;
  display: block;
  text-decoration: none;
  background: var(--bg-secondary);
  padding: 20px 60px 20px 40px;
  border-radius: 80px;
  position: relative;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  outline: none;

  .icon-button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &:focus {
    @include expandFill;
  }
}

.link-pill__title {
  max-width: calc(100% - 50px);
  .mobile {
    display: block;
    @include breakpoint('md+') {
      display: none;
    }
  }

  .desktop {
    display: none;
    @include breakpoint('md+') {
      display: block;
    }
  }
}
