.video-player__wrapper {
  @include aspectRatio(16, 9);
  @include imageBorder;
  background-color: black;
  iframe {
    height: 100%;
    width: 100%;
  }

  video {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.video-player__poster {
  @include aspectRatio(16, 9);
  overflow: hidden;
  z-index: 1;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.video-player__play-btn {
  @include buttonReset;
  border-radius: 100%;
  background: var(--bg-interactive);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 72px;
  width: 72px;
  z-index: 2;
  &:hover {
    background: var(--red-85);
  }

  &:active {
    background: var(--red-80);
  }

  &[data-focus-method='key']:focus {
    @include outline;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 3px;
    transform: translate(-50%, -50%);
  }
}

.video-player__content {
  margin-top: 16px;
  span {
    display: block;
  }
  .video-player__title {
    @extend %f-ed-heading-1;
    padding-bottom: 8px;
  }

  .video-player__description {
    @extend %f-caption;
    color: var(--text-secondary);
  }
}
