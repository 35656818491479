.pull-quote {
  blockquote {
    @extend %f-subhead-3;
    margin-top: 17px;
    @include breakpoint('lg+') {
      padding-right: 20px;
    }
  }
  svg {
    display: block;
  }

  figCaption {
    display: block;
    margin-top: 20px;
    @include breakpoint('md+') {
      margin-top: 24px;
    }
  }
}

.pull-quote__byline {
  @extend %f-caption;
  display: block;
}

.pull-quote__title {
  @extend %f-caption;
  display: block;
  color: var(--text-secondary);
}

.block--qull-quote {
  @include smallSpacing;

  .pull-quote {
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
      margin-left: auto;
    }
  }
}

.pull-quote-wrapper {
  display: flex;
}
