.global-footer {
  margin-top: 96px;
  @include breakpoint('md+') {
    margin-top: 128px;
  }
  @include breakpoint('md+') {
    margin-top: 192px;
  }
}

.global-footer__content {
  border-top: 1px solid var(--border-primary);
  @include breakpoint('md+') {
    @include gridContainer();
  }
}

.global-footer__text {
  @extend %f-subhead-3;
  margin-top: 20px;
  @include breakpoint('md+') {
    @include colspan(5);
    margin-top: 16px;
  }

  .free-text {
    @include breakpoint('sm') {
      display: none;
    }
    @include breakpoint('md+') {
      display: block;
    }
    @include breakpoint('xl+') {
      display: none;
    }
  }
  .locked-text {
    display: none;
    @include breakpoint('sm') {
      display: block;
    }
    @include breakpoint('md+') {
      display: none;
    }
    @include breakpoint('xl+') {
      display: block;
    }
  }
}

.global-footer__branding {
  @include breakpoint('md+') {
    @include gridColSpan(6);
  }
}

.global-footer__primary,
.global-footer__secondary {
  @include breakpoint('md+') {
    @include gridColSpan(3);
  }
  ul {
    margin-top: 48px;
    @include breakpoint('md+') {
      margin-top: 20px;
    }
  }
  li {
    margin-top: 24px;
    @include breakpoint('md+') {
      margin-top: 20px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}

.global-footer__social {
  margin-top: 34px;
  ul {
    display: flex;
  }
}

footer {
  .global-footer__secondary {
    a#ot-sdk-btn {
      @extend %f-ui-4;
      transition: none;
      color: var(--text-secondary);
      border: none;
      padding: 0;

      &:hover {
        color: var(--text-text-interactive);
        background: none;
        .link__text {
          @include underline();
        }
      }
    }
  }
}

.global-footer__legal {
  @extend %f-ui-4;
  color: var(--text-secondary);
  margin-top: 48px;
  padding-bottom: 64px;
  @include breakpoint('md+') {
    margin-top: 128px;
    padding-bottom: 20px;
  }
  @include breakpoint('lg+') {
    margin-top: 192px;
  }
}
