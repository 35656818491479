@mixin spacing {
  margin-top: 64px;
  @include breakpoint('md+') {
    margin-top: 80px;
  }
  @include breakpoint('lg+') {
    margin-top: 96px;
  }
}

@mixin smallSpacing {
  margin-top: 48px;
  @include breakpoint('md+') {
    margin-top: 64px;
  }
  @include breakpoint('lg+') {
    margin-top: 80px;
  }
}
