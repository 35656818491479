.search-input {
  position: relative;
  width: 100%;
  form {
    width: 100%;
    display: flex;
  }
  label {
    @include hidden;
  }
  input {
    @extend %f-heading-1;
    -webkit-appearance: none;
    border: 0;
    border-radius: 1px;
    width: 100%;
    padding: 12px 0;
    color: var(--color-primary);
    &::placeholder {
      color: #64778e;
    }
    outline: none;
    border: none;
    box-shadow: inset 0 -2px 0 -1px var(--border-interactive);
    &:focus[data-focus-method='mouse'] {
      box-shadow: inset 0 -2px 0 0 var(--text-primary);
    }

    &:focus[data-focus-method='key'] {
      box-shadow: inset 0 -2px 0 0 var(--text-primary);
    }
  }
}

.search-input__actions {
  position: absolute;
  flex-shrink: 0;
  display: flex;
  right: 0;
  top: 14px;

  button {
    @include buttonReset;
    position: relative;
    &[data-focus-method='key']:focus {
      @include textOutline;
    }

    &:nth-child(2) {
      margin-left: 40px;
      position: relative;
      &:before {
        content: '';
        height: 100%;
        width: 1px;
        background: #a6b7cd;
        position: absolute;
        left: -20px;
      }
    }
  }
  .search-input__clear {
    color: var(--text-secondary);
  }
  .search-input__search {
    color: var(--text-text-interactive);
  }
}
