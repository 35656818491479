.tab-module {
  @include spacing;
}
.tabs__content-area {
  display: none;
  &.is-active {
    display: block;
  }
}

.tab-module__title {
  @extend %f-ui-8;
}

.tab-module__list {
  margin-top: -20px;
  padding-top: 24px;
  li {
    margin-top: 20px;
  }
  @include breakpoint('md+') {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 12px;
    }
  }
}

.tab-module__info {
  margin-top: 32px;
  @extend %f-ui-4;
  color: var(--text-secondary);
  a {
    color: var(--text-secondary);
    text-decoration: none;
    @include underline(var(--text-secondary));
    &:hover {
      color: var(--red-85);
      @include underline(var(--red-85));
    }
  }
}

.tabs__content {
  margin-top: 32px;
}

.block__form-tabs {
  //display: flex;
  .tab-module {
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
      margin-left: auto;
    }
  }
}
