.feature-grid__listing {
  @include spacing;

  .hidden {
    display: none;
  }

  .feature {
    @include gridColSpan(12);
    @include breakpoint('md+') {
      @include gridColSpan(12);
    }
    @include breakpoint('lg+') {
      @include gridColSpan(6);
    }
  }
  .simple {
    @include gridColSpan(12);
    @include breakpoint('md+') {
      @include gridColSpan(6);
    }
    @include breakpoint('lg+') {
      @include gridColSpan(3);
    }
  }
}

.feature-grid__grid--secondary {
  margin-top: 64px;
  @include breakpoint('md+') {
    margin-top: 96px;
  }
  @include breakpoint('lg+') {
    margin-top: 128px;
  }
}

.feature-grid__grid {
  @include gridContainer;
  gap: 64px 20px;
  @include breakpoint('md+') {
    gap: 96px 20px;
  }
  @include breakpoint('lg+') {
    gap: 128px 20px;
  }
}

.feature-grid__link {
  @include spacing;
}
