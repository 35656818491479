.button {
  @extend %f-ui-5;
  @include buttonReset;
  position: relative;
  display: inline-flex;
  padding: 10px 32px 13px;
  border-radius: 48px;

  span {
    z-index: 1;
    position: relative;
  }
}

.button {
  &[data-focus-method='key']:focus {
    @include outline;
  }
}

.button.button--disabled {
  cursor: default;
  pointer-events: none;
}

.button {
  color: var(--red-90);
  background: var(--white);
}

.button--primary {
  transition: all 0.3s;
  &:hover {
    background: var(--red-90);
    color: white;
  }

  &:active {
    background: var(--red-85);
  }

  &[data-focus-method='key']:focus {
    color: white;
    background: var(--red-90);
  }
}

.button--secondary {
  color: var(--white);
  background: var(--red-90);
  transition: color 0.3s, background 0.3s;
  &:hover {
    background: var(--red-85);
  }

  &:active {
    background: var(--red-80);
  }

  &[data-focus-method='key']:focus {
    background: var(--red-85);
  }
}

.button--ghost {
  background: none;
  border: 1px solid var(--grey-60);
  transition: color 0.3s, background 0.3s;
  &:hover {
    background: var(--red-90);
    border-color: var(--red-90);
    color: white;
  }

  &:active {
    background: var(--red-80);
    border-color: 1px solid var(--red-80);
  }

  &[data-focus-method='key']:focus {
    background: var(--red-85);
    border-color: 1px solid var(--red-85);
    color: white;
  }
}
