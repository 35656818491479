.pagination__controls {
  display: flex;
  border-top: 1px solid var(--border-primary);
  margin-top: 30px;

  .pagination__btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include breakpoint('md+') {
      justify-content: unset;
    }
  }
}
.pagination__button {
  @include buttonReset;
  margin-top: 20px;
  position: relative;
  background-color: var(--bg-secondary);
  border-radius: 48px;
  width: 157px;
  &:first-child {
    margin-right: 20px;
  }
  @include breakpoint('md+') {
    width: 184px;
  }

  @extend %f-ui-5;
  &:hover {
    background-color: var(--bg-tertiary);
  }
  &[data-focus-method='key']:focus {
    @include outline;
    background-color: var(--bg-tertiary);
  }

  display: inline-flex;
  align-items: center;
  width: 157px;
  justify-content: space-between;
}

.pagination__button--next {
  padding: 10px 20px 13px 32px;
  svg {
    display: block;
    margin-left: 20px;
  }
}
.pagination__button--prev {
  padding: 10px 32px 13px 20px;
  svg {
    display: block;
    margin-right: 20px;
  }
}

.pagination__description {
  @extend %f-ui-5;
  text-align: center;
  color: var(--text-secondary);
  margin-top: 20px;
  @include breakpoint('md+') {
    display: none;
  }
}

.pagination__info {
  width: 100%;
  margin-top: 30px;
  display: none;
  @include breakpoint('md+') {
    display: block;
  }
}

.pagination__select-wrap {
  @extend %f-ui-5;

  display: flex;
  justify-content: end;
}
