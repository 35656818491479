.hero-landing-l1 {
  margin-top: 20px;
  @include breakpoint('md+') {
    margin-top: 64px;
  }
}

.hero-landing-l1__media {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include breakpoint('md+') {
    flex-direction: row;
    justify-content: space-between;
  }
  .image:nth-child(1) {
    @include breakpoint('md+') {
      @include colspan(9);
    }
  }
  .image:nth-child(2) {
    @include breakpoint('md+') {
      @include colspan(3);
    }
  }
}

.hero-landing-l1__media--1 {
  @include imageBorder;
  //   background: var(--bg-tertiary);
  height: 100%;
  order: 2;
  aspect-ratio: 1/1;
  margin-top: 20px;

  @include breakpoint('md+') {
    aspect-ratio: 8/5;
    order: 1;
    margin-top: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.hero-landing-l1__media--2 {
  @include imageBorder;
  //   background: var(--bg-tertiary);
  height: 100%;
  aspect-ratio: 8/5;
  order: 1;

  @include breakpoint('md+') {
    aspect-ratio: 1/1;
    order: 2;
  }

  img {
    height: 100%;
    object-fit: fill;
  }
}

.hero-landing-l1__title {
  @extend %f-heading-8;
  margin-top: 16px;
  @include breakpoint('md+') {
    margin-top: 8px;
  }

  @include breakpoint('lg+') {
    @include colspan(9);
  }
}

.hero-landing-l1__intro {
  @extend %f-subhead-3;
  border-top: 1px solid var(--border-primary);
  padding-top: 20px;
  margin-top: 80px;
  flex-direction: column;
  @include breakpoint('md+') {
    margin-top: 96px;
  }
  @include breakpoint('lg+') {
    margin-top: 128px;
    display: flex;
  }

  span {
    display: block;
    @include breakpoint('md+') {
      @include colspan(9);
    }

    @include breakpoint('lg+') {
      @include colspan(6);
      margin-left: auto;
      padding-right: 20px;
    }
  }
}

.hero-landing-l1__cta {
  margin-top: 40px;
  @include breakpoint('md+') {
    margin-top: 48px;
    @include colspan(6);
    margin-left: auto;
  }
}
