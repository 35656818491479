.search-panel {
  position: absolute;

  left: -9999px;
  top: 100%;
  width: 100%;

  .search-panel__inner {
    background: white;
    height: 180px;
    width: 100%;
    border-radius: 0 0 20px 20px;
    position: absolute;
    top: 0;
  }
  .search-input {
    @include colspan(6);
    margin: 0 auto;
    margin-top: 48px;
  }
  .search-panel__close {
    @include buttonReset;
    position: absolute;
    background-color: white;
    z-index: 7;
    top: -67px;
    right: 20px;
    &[data-focus-method='key']:focus {
      @include textOutline;
    }
  }
}
