@mixin background-fill {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
    pointer-events: none;
  }
}

%background-fill,
.background-fill {
  @include background-fill;
}
