.icon-button {
  @extend %f-ui-5;
  @include buttonReset;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  position: relative;
  transition: all 0.3s;
  display: block;
  span {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.icon-button {
  &[data-focus-method='key']:focus {
    @include outline;
  }
}

.icon-button--primary {
  position: relative;
  color: var(--red-90);
  background: var(--white);

  div[data-list-position]:hover &,
  &:hover,
  &[data-focus-method='key']:focus {
    color: white;
    background: var(--red-90);
  }

  &:active {
    background: var(--red-85);
  }
}

.icon-button--ghost {
  &:hover {
    color: var(--grey-60);
  }
}

.icon-button--btn-small {
  box-shadow: 0px 2px 6px 2px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
  background-color: white;
  &:hover {
    color: var(--grey-60);
  }
}

.icon-button--btn-large {
  height: 56px;
  width: 56px;
  background-color: white;

  &:hover {
    color: var(--grey-60);
  }
}
