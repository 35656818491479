.search-result {
  display: block;
  text-decoration: none;
  display: flex;
  color: var(--text-primary);
  justify-content: space-between;
  border-top: 1px solid var(--border-primary);
  padding-top: 20px;
  outline: none;

  &:hover,
  &:focus {
    .search-result__title h3 {
      color: var(--text-text-interactive);
      @include underline;
    }
  }
}

.search-result__inline-media {
  @include imageBorder;
  aspect-ratio: 1/1;
  float: right;
  height: 98px;
  width: 98px;
  margin-bottom: 16px;
  margin-left: 20px;
  img {
    height: 100%;
    object-fit: cover;
  }
  @include breakpoint('sm+') {
    display: none;
  }
}

.search-result__media {
  display: none;
  @include breakpoint('sm+') {
    display: block;
  }
}

.search-result--no-image {
  @include breakpoint('sm') {
    .search-result__content {
      @include colspan(12);
    }

    .search-result__media {
      display: none;
    }
  }
}

.search-result__label {
  @extend %f-ui-1;
  text-transform: uppercase;
  padding-bottom: 12px;
}

.search-result__title {
  @extend %f-heading-2;
  h3 {
    display: inline;
  }
}

.search-result__content {
  @include colspan(12);
  @include breakpoint('sm+') {
    @include colspan(8);
    padding-right: 20px;
  }
  @include breakpoint('lg+') {
    @include colspan(8);
    padding-right: 0;
  }
}

.search-result__description {
  @extend %f-body-1;
  margin-top: 12px;
}

.search-result__secondary {
  margin-top: 20px;
}

.search-result__author {
  @extend %f-ui-4;
  padding-bottom: 4px;
}

.search-result__date {
  @extend %f-ui-4;
  color: var(--text-secondary);
}

.search-result__media {
  @include colspan(4);
  @include breakpoint('md+') {
    @include colspan(3);
  }
}

.search-result__img {
  @include imageBorder;
  aspect-ratio: 1/1;
  @include breakpoint('lg+') {
    aspect-ratio: 8/5;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.search-result--simple {
  height: 100%;
  padding-bottom: 20px;

  @include breakpoint('lg+') {
    padding-bottom: 96px;
  }
  .search-result__description {
    display: none;
  }
  .search-result__media {
    display: none;
  }
}

.job-card {
  display: block;
  text-decoration: none;
  display: flex;
  color: var(--text-primary);
  justify-content: space-between;
  border-top: 1px solid var(--border-primary);
  padding-top: 20px;
  flex-direction: column;
  outline: none;
  padding-bottom: 20px;
  @include breakpoint('md+') {
    padding-bottom: 80px;
  }
  @include breakpoint('lg+') {
    padding-bottom: 96px;
  }

  &:hover,
  &:focus {
    .job-card__title span {
      color: var(--text-text-interactive);
      @include underline;
    }
  }
}

.job-card__title {
  @extend %f-heading-2;
}

.job-card__info {
  margin-top: 20px;
}

.job-card__primary,
.job-card__secondary {
  @extend %f-ui-4;
}

.job-card__primary {
  padding-bottom: 4px;
}

.job-card__secondary {
  color: var(--text-secondary);
}
