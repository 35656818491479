.chip {
  @extend %f-ui-5;
  @include buttonReset;
  position: relative;
  color: var(--text-primary);
  border: 1px solid var(--border-interactive);
  text-decoration: none;
  padding: 10px 24px 13px;
  display: block;
  border-radius: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .chip__icon {
    display: none;
    padding-right: 20px;
    position: relative;
    top: 2px;
  }

  &.is-active {
    border: 2px solid #00172d;
    background: var(--bg-secondary);
    padding-left: 22px;

    .chip__icon {
      display: block;
    }
  }

  &[data-focus-method='key']:focus {
    @include outline;
  }
}
