.hero-feature {
  color: var(--text-text-accent);
  overflow: hidden;
}

.hero-feature--blank-bg {
  color: var(--text-primary);
}

.hero-feature__color-wrap {
  @include background-fill;
  background: var(--bg-accent-1);

  padding-top: 20px;
  padding-bottom: 80px;
  @include breakpoint('md+') {
    padding-bottom: 96px;
  }
  @include breakpoint('lg+') {
    padding-bottom: 128px;
  }

  .hero-feature--blue & {
    background: var(--bg-accent-1);
  }

  .hero-feature--green & {
    background: var(--bg-accent-2);
  }

  .hero-feature--yellow & {
    background: var(--bg-accent-3);
  }

  .hero-feature--pink & {
    background: var(--bg-accent-4);
  }
}

.hero-feature__label {
  @extend %f-ui-1;
  display: block;
  text-transform: uppercase;
}

.hero-feature__heading {
  @extend %f-heading-6;
  margin-top: 40px;

  @include breakpoint('lg+') {
    margin-top: 60px;
  }
  h1,
  .hero-feature__subtitle {
    margin-top: 16px;
    @include breakpoint('md+') {
      @include colspan(10);
    }
  }
}

.hero-feature__subtitle {
  color: var(--text-secondary);
}

.hero-feature__media {
  @include imageBorder;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.hero-feature__intro {
  @extend %f-subhead-3;
  color: var(--text-primary);
  padding-top: 20px;
  @include breakpoint('lg+') {
    display: flex;
    justify-content: flex-end;
  }
  span {
    display: block;
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
    }
  }
}

.hero-feature--blank-bg {
  color: var(--text-primary);
  .hero-feature__color-wrap {
    background: white;
  }

  .hero-feature__intro {
    border-top: 1px solid var(--border-primary);
  }
}
.hero-feature__video {
  aspect-ratio: 2;
}

.hero-feature__video .video-player__wrapper:before {
  padding-top: 50%;
}
.hero-feature__video .video-player__poster:before {
  padding-top: 50%;
}

.hero-feature__rail {
  margin-top: 40px;

  @include breakpoint('lg+') {
    position: absolute;
    margin-top: 28px;
  }
}
.hero-feature__rail > div:nth-child(1):not(.hero-editorial__btn) {
  margin-top: 0;
}