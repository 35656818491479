.moment-grid {
  width: 100% !important;
  @include spacing;
}

.moment-grid-wrap {
  width: 100%;
}

.test-brick {
  @include colspan(12);
  @include breakpoint('md+') {
    @include colspan(6);
  }
  height: 300px;
  margin-top: 20px;
}
