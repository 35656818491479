.tag {
  @extend %f-ui-2;
  display: inline-flex;
  outline: none;
  color: var(--text-primary);
  text-decoration: none;
  border: 1px solid var(--border-interactive);
  padding: 6px 12px;
  border-radius: 32px;
  position: relative;
  &:hover {
    border-color: var(--text-primary);
  }
  &[data-focus-method='key']:focus {
    border-color: var(--text-primary);
    &:after {
      content: '';
      position: absolute;
      left: -0.5px;
      top: -0.5px;
      height: calc(100% + 1px);
      width: calc(100% + 1px);
      border-radius: 48px;
      box-shadow: 0px 0px 0px 2px white, 0px 0px 0px 4px black,
        0px 0px 0px 6px white;
    }
  }
}
