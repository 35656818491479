.rt-col {
  @include breakpoint('md+') {
    .rt-module {
      @include colspan(10);
    }
  }
  @include breakpoint('lg+') {
    display: flex;
    justify-content: end;
    .rt-module {
      @include colspan(6);
      padding-right: 20px;
    }
  }
}
.rt-module {
  @extend %f-body-3;

  & > :first-child {
    margin-top: 0;
  }

  img {
    @include imageBorder;
    @include smallSpacing;
    width: 100%;
    height: auto;
  }

  figure figcaption {
    color: var(--text-secondary);
    @extend %f-caption;
    margin-top: 16px;
  }

  a {
    @include underline;
    text-decoration: none;
    position: relative;
    color: var(--text-text-interactive);
    &[data-focus-method='key']:focus {
      @include textOutline;
    }
  }

  strong {
    @include richTextBold;
  }

  em {
    font-style: italic;
  }

  h1,
  h3 {
    @extend %f-heading-2;
    margin-top: 48px;
    @include breakpoint('md+') {
      margin-top: 64px;
    }
  }
  h2,
  h4 {
    @extend %f-ed-heading-2;
    margin-top: 40px;
    @include breakpoint('md+') {
      margin-top: 48px;
    }
  }

  p {
    margin-top: 24px;
  }

  ol,
  ul {
    margin-top: 24px;
    margin-left: 29px;
  }

  li {
    position: relative;
    margin-top: 12px;
  }

  ol {
    list-style: none;
    counter-reset: item;
    ol {
      margin-left: 48px;
    }
  }

  ol > li:before {
    position: absolute;
    right: 100%;
    margin-right: 8px;
    counter-increment: item;
    content: counter(item, decimal) '. ';
  }

  ol ol {
    counter-reset: letter;
  }
  ol ol li:before {
    position: absolute;
    right: 100%;
    margin-right: 8px;
    counter-increment: letter;
    content: counter(letter, lower-alpha) '. ';
  }

  ol ol ol {
    counter-reset: letter;
  }

  ol ol ol li:before {
    position: absolute;
    right: 100%;
    margin-right: 8px;
    counter-increment: letter;
    content: counter(letter, lower-roman) '. ';
  }

  ul {
    counter-reset: item;
    list-style: none;
    ul {
      margin-top: 0;
      margin-left: 0;
      margin-left: 48px;
    }
    li {
      &:before {
        content: counter(item, disc) ' ';
        position: absolute;
        left: -18px;
      }
      li {
        &:before {
          content: counter(item, circle) ' ';
          position: absolute;
          left: -18px;
        }
        li {
          &:before {
            content: '▪ ';
            position: absolute;
            left: -18px;
          }
        }
      }
    }
  }
}

.rt-module--hero {
  @extend %f-subhead-3;
}

// default spacing
.block--rich-text {
  margin-top: 24px;
}

// special spacing
.block--editorial-media + .block--rich-text,
.block--full-width-media + .block--rich-text,
.block--cta + .block--rich-text,
.block--qull-quote + .block--rich-text,
.block--grid-list + .block--rich-text,
.block--button-module + .block--rich-text,
.block--section-header-grid + .block--rich-text {
  @include smallSpacing;
}

.block--standfirst + .block--rich-text {
  margin-top: 48px;
}

.block--rich-text + .block--rich-text {
  margin-top: 0;
  .rt-module {
    & > h2:first-child,
    & > h3:first-child {
      margin-top: 48px;
      @include breakpoint('md+') {
        margin-top: 64px;
      }
    }

    & > h4:first-child {
      margin-top: 40px;
      @include breakpoint('md+') {
        margin-top: 48px;
      }
    }

    & > p:first-child,
    & > ol:first-child,
    & > ul:first-child {
      margin-top: 24px;
    }
  }
}

// Typography styles for rich text
sup {
  font-size: small;
  vertical-align: super;
}
sub {
  font-size: small;
  vertical-align: sub;
}

.scroll-margin-top{
  scroll-margin-top: var(--nav-height);
}

.intertitle {
  font-family: var(--aeo-400);
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 1.125rem;
  letter-spacing: 0.0125em;
  line-height: 120%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 32px;
}

.contact--page--blocks--wraper {
  padding-top: 48px;
  .block--rich-text {
    .rt-module {
      font-size: 1.3125rem
    }
  }
}
