@import '@styles/scss-utilities-main/reset';

@import '@styles/scss-utilities-main/docs/tokens';
@import '@styles/scss-utilities-main/a17-scss-utilities';
@import '@styles/mixins/_mixins';

@import '@styles/typography/_typography';

body {
  @extend %f-body-1;
  width: 100%;
  color: var(--text-primary);
}

html,
body {
  height: 100vh;
  height: var(--app-height);
  scroll-padding-top: 50px;
}

::selection {
  background: var(--text-primary);
  color: white;
}
::-moz-selection {
  background: var(--text-primary);
  color: white;
}

#content {
  outline: none;
}

.block--placeholder {
  @extend %f-heading-4;
  margin-top: 96px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: var(--bg-secondary);
  text-align: center;

  @include breakpoint('lg+') {
    margin-top: 128px;
  }

  @include breakpoint('xl+') {
    margin-top: 192px;
  }
}

a[data-focus-method='key']:focus,
button[data-focus-method='key']:focus {
  outline: 4px solid #00172d;
}

@import '@styles/components/_components';
