.moment-image {
  background: var(--grey-95);
  color: var(--navy-20);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  overflow: hidden;
  width: 100%;
  @include colspan(12);
  @include breakpoint('md+') {
    @include colspan(6);
  }
  @include breakpoint('lg+') {
    flex-direction: row;
  }

  &:hover,
  &:focus {
    @include expandFill;
  }
}

.moment-image__content {
  padding: 24px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  @include breakpoint('lg+') {
    @include colspan(3);
    order: 1;
  }
}

.moment-image__image {
  overflow: hidden;
  aspect-ratio: 8/5;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @include breakpoint('lg+') {
    aspect-ratio: 1/1;
    flex-shrink: 0;
  }
  @include breakpoint('xl+') {
    height: auto;
    @include colspan(3);
    order: 2;
    margin-left: var(--inner-gutter);
    aspect-ratio: 1/1;
  }
}

.moment-image__heading {
  @extend %f-heading-1;
}

.moment-image__eyebrow {
  @extend %f-ui-1;
  text-transform: uppercase;
  padding-bottom: 16px;
  display: block;
  display: none;
}

.moment-image__body {
  @extend %f-body-1;
  margin-top: 12px;
}

.moment-image__cta {
  @extend %f-ui-4;
}

.moment-image__secondary {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  align-items: center;

  @include breakpoint('lg+') {
    margin-top: auto;
    padding-top: 20px;
  }

  .icon-button {
    align-self: end;
  }
}

.moment-image__wrapper {
  @include breakpoint('md+') {
    display: flex;
  }
}
