.cta-card {
  margin-top: 96px;
  display: block;
  color: white;
  text-decoration: none;
  @include breakpoint('md+') {
    margin-top: 128px;
  }
  @include breakpoint('lg+') {
    margin-top: 192px;
  }
  &:hover {
    @include expandFill;
  }
}

.cta-content {
  background: var(--navy-20);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 8px;
  @include colspan(12);
  @include breakpoint('lg+') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.cta-primary {
  flex-shrink: 0;
  @include aspectRatio(8, 5);
  @include colspan(12);
  overflow: hidden;
  @include breakpoint('lg+') {
    @include colspan(6);
  }
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.cta-secondary {
  padding: 24px 20px 48px 20px;
  @include breakpoint('md+') {
    @include colspan(10);
    padding-right: 0;
  }
  @include breakpoint('lg+') {
    @include colspan(6);
    padding-left: 0;
    padding-right: 20px;
  }
}

.cta-label {
  @extend %f-ui-1;
  text-transform: uppercase;
}

.cta-title {
  @extend %f-heading-3;
  margin-top: 12px;
}

.cta-tagline {
  @extend %f-body-1;
  margin-top: 12px;
}

.cta-secondary__info {
}

.cta-secondary__button {
  margin-top: 32px;
}

.cta-card--no-image {
  .cta-secondary {
    @include breakpoint('lg+') {
      padding-left: 20px;
    }
  }
}
