.section-title {
  margin-top: 96px;
  @include breakpoint('lg+') {
    margin-top: 128px;
  }

  @include breakpoint('xl+') {
    margin-top: 192px;
  }
}

.section-title__content {
  border-top: 1px solid var(--border-primary);
}

.section-title__title {
  @extend %f-heading-5;
  margin-top: 12px;
  @include breakpoint('md+') {
    @include colspan(10);
  }

  @include breakpoint('lg+') {
    @include colspan(8);
  }
}

.section-title__intro {
  @extend %f-subhead-1;
  margin-top: 48px;
  @include breakpoint('md+') {
    @include colspan(6);
    margin-top: 16px;
  }
}

.section-title--with-intro {
  @include breakpoint('lg+') {
    margin-top: 192px;
  }

  .section-title__content {
    @include breakpoint('md+') {
      display: flex;
    }
  }
  .section-title__title {
    @include breakpoint('md+') {
      @include colspan(6);
    }
    @include breakpoint('lg+') {
      @include colspan(5);
    }
  }

  .section-title__intro {
    @include breakpoint('md+') {
      margin-left: var(--inner-gutter);
    }
    @include breakpoint('lg+') {
      @include colspan(3);
      margin-left: colspan(1, calc(var(--inner-gutter) * 2));
    }
  }
}

.section-title--with-grid {
  @include breakpoint('lg+') {
    margin-top: 192px;
  }

  .section-title__content {
    @include breakpoint('lg+') {
      display: flex;
      justify-content: space-between;
    }
  }

  .section-title__title {
    @include breakpoint('lg+') {
      @include colspan(5);
    }
  }

  .section-title__grid {
    @include breakpoint('lg+') {
      @include colspan(6);
    }
  }

  .grid-list {
    margin-top: 48px;
    @include breakpoint('md+') {
      margin-top: 64px;
    }

    @include breakpoint('lg+') {
      margin-top: 20px;
    }
  }
}

.section-title--with-rich-text {
  .section-title__content {
    @include breakpoint('lg+') {
      display: flex;
      justify-content: space-between;
    }
  }
  .section-title__title {
    @include breakpoint('lg+') {
      @include colspan(5);
    }
  }
  .section-title__rich-text {
    margin-top: 48px;
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
      padding-right: 20px;
      margin-top: 0;
    }
  }
  .rt-module {
    margin-top: 24px;
  }
}
