/*
  @function str-replace

  Replace item in string with new string

  Parameters:
  $string - string
  $search - item to replace
  $replace - what to replace with
*/

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}
