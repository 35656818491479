.link {
  @extend %f-ui-5;
  border: 0;
  background: none;
  color: var(--text-text-interactive);
  text-decoration: none;
  position: relative;
  outline: none;

  &[data-focus-method='key']:focus {
    @include textOutline;
  }

  .link__chevron {
    padding-left: 6px;
  }
}

.link.link--small {
  @extend %f-ui-4;
}

.link--primary {
  &:hover {
    .link__text {
      color: var(--red-85);
      background-image: linear-gradient(
        to bottom,
        var(--red-85) 75%,
        var(--red-85) 75%
      );
    }
  }
}

.link.link--simple {
  .link__text {
    background-image: none;
  }
}

.link__text {
  background-image: linear-gradient(
    to bottom,
    var(--text-text-interactive) 75%,
    var(--text-text-interactive) 75%
  );
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 98%;
}

.link__icon--left {
  position: relative;
  top: 3px;
}

.link--dark {
  color: var(--text-primary);
  .link__text {
    background-image: linear-gradient(
      to bottom,
      var(--text-primary) 75%,
      var(--text-primary) 75%
    );
  }

  &:hover {
    color: var(--text-text-interactive);
    svg {
      color: var(--text-text-interactive);
    }
    .link__text {
      background-image: linear-gradient(
        to bottom,
        var(--text-text-interactive) 75%,
        var(--text-text-interactive) 75%
      );
    }
  }
}

.link--secondary {
  color: var(--text-secondary);
  .link__text {
    background-image: linear-gradient(
      to bottom,
      var(--text-secondary) 75%,
      var(--text-secondary) 75%
    );
  }

  &:hover {
    color: var(--text-text-interactive);
    svg {
      color: var(--text-text-interactive);
    }
    .link__text {
      background-image: linear-gradient(
        to bottom,
        var(--text-text-interactive) 75%,
        var(--text-text-interactive) 75%
      );
    }
  }
}

.block--link-module {
  @include spacing;
}
