.author-card-list {
  display: block;
  padding-top: 80px;
  @include breakpoint('md+') {
    padding-top: 96px;
  }
  position: relative;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  @include colspan(12);
  @include breakpoint('lg+') {
    align-items: flex-end;
    padding-top: 128px;
  }
}

.author-card-list__inner {
  @include colspan(12);
  @include breakpoint('md+') {
    @include colspan(9);
  }
  @include breakpoint('lg+') {
    @include colspan(6);
  }
}

.author-card-list__about {
  @extend %f-heading-2;
  border-top: 1px solid var(--border-primary);
  padding-top: 12px;
}

.author-card-list__authors {
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: 64px;
  }
}
