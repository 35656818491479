.global-header--mobile {
  position: sticky;
  width: 100%;
  z-index: 3;
  top: 0;
  background: white;

  @include breakpoint('lg+') {
    display: none;
  }

  .global-header__logo {
    position: relative;
    height: 48px;
    top: -3px;

    svg {
      height: 48px;
    }
  }

  .global-header__content {
    display: flex;
    height: var(--nav-height);
    align-items: center;
  }

  .global-header__mobile-actions {
    margin-left: auto;
    display: flex;
    position: relative;
    top: -1px;
    button {
      @include buttonReset;
      margin-left: 24px;
      display: flex;
      align-items: center;
      position: relative;
      &[data-focus-method='key']:focus {
        @include textOutline;
      }
    }
  }

  .global-header__nav {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    left: -100%;
    z-index: 3;
    @include breakpoint('md+') {
      overflow: hidden;
      border-radius: 20px 0 0 20px;
      left: auto;
      right: 100%;
      width: calc(#{colspan(6)} + 20px);
      z-index: 3;
    }
  }

  .nav {
    height: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .nav__header {
    position: relative;
  }

  .nav__items {
    background-color: white;
    flex-grow: 1;
    padding: 0 20px;
  }

  .nav__group__panel {
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 5;
    @include breakpoint('md+') {
      right: -100%;
      width: calc(#{colspan(6)} + 20px);
      overflow: hidden;
      border-radius: 20px 0 0 20px;
    }
  }

  .nav__back {
    @include buttonReset;
    @extend %f-ui-5;
    color: var(--text-secondary);
    transition: opacity 0.4s;

    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
  .nav__group__sections {
    flex-grow: 1;
    padding: 0 20px;
    background-color: white;
    overflow: auto;
    padding-bottom: 96px;
  }

  .mobile-panel-header {
    background: white;
    display: flex;
    height: 88px;
    align-items: center;
    position: relative;
    padding: 0 20px;

    .logo {
      svg {
        height: 48px;
      }
    }
  }

  .mobile-panel-header__actions {
    margin-left: auto;
    display: flex;
    position: relative;
    top: -1px;

    button {
      @include buttonReset;
      display: flex;
      margin-left: 24px;
      position: relative;
      &[data-focus-method='key']:focus {
        @include textOutline;
      }
    }
  }

  .nav__group-button,
  .nav__link {
    margin-top: 20px;
    @include buttonReset;
    @extend %f-ui-9;
    position: relative;
    display: flex;
    align-items: center;
    svg {
      display: block;
      margin-left: 8px;
    }

    &[data-focus-method='key']:focus {
      @include textOutline;
    }
  }

  .nav__link {
    display: inline-flex;
  }

  .nav__secondary-links {
    margin-top: 40px;
    li {
      margin-top: 24px;
    }
  }

  .nav__group__section {
    border-top: 1px solid var(--border-primary);
    &:last-child {
      border-bottom: 1px solid var(--border-primary);
    }

    button {
      @include buttonReset;
      @extend %f-heading-1;
      width: 100%;
      padding: 20px 0px;
      display: flex;
      justify-content: space-between;
      position: relative;
      transition: 0.2s color;
      &[data-focus-method='key']:focus {
        @include textOutline;
      }
      svg {
        transition: 0.2s all;
      }
      &.is-active {
        color: var(--text-text-interactive);
        svg {
          transform: rotate(180deg);
        }
      }
    }

    li {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        padding-bottom: 20px;
      }
      a {
        @extend %f-ui-4;
        display: block;
        text-decoration: none;
        color: var(--text-primary);
        padding: 20px 16px;
      }
    }
  }

  .search-panel {
    // left: 0;
    top: 0;
    z-index: 3;

    .search-panel__header {
      padding: 0 20px;
      height: var(--nav-height);
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      z-index: 3;
      position: relative;
    }

    .search-panel__logo {
      svg {
        height: 48px;
      }
    }
    .search-panel__inner {
      position: relative;
      background-color: white;
      height: 165px;
      padding-top: 48px;
      z-index: 2;
    }
    .search-input {
      margin-top: 0;
      width: 100%;
    }
  }

  .search-panel__actions {
    display: flex;
    align-items: center;
    button {
      @include buttonReset;
      position: relative;
      margin-left: 24px;
      &[data-focus-method='key']:focus {
        @include textOutline;
      }
    }
  }
}

.mobile-feature {
  display: block;
  background: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 8px;
  padding: 16px 20px;
  margin-bottom: 20px;
  text-decoration: none;
  outline: none;
  margin-top: 20px;

  &:hover,
  &:focus {
    @include expandFill;
  }

  .icon-button {
    flex-shrink: 0;
    margin-left: auto;
    margin-left: 20px;
  }
}

.mobile-feature__title {
  @extend %f-heading-1;
  text-transform: none;
}

.mobile-feature__content {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
}

.mobile-feature__description {
  @extend %f-body-1;
}
