.media-list {
  & > div {
    @include smallSpacing;
  }

  @include breakpoint('lg+') {
    @include gridContainer;
  }
}

.media-list__left {
  @include breakpoint('lg+') {
    @include gridColSpan(6);
  }
}
.media-list__right {
  @include breakpoint('lg+') {
    @include gridColSpan(6);
  }
}
