/*
  @function get-smallest-breakpoint

  Returns the name of the smallest breakpoint
*/

@function get-smallest-breakpoint($points) {
  // if set by the build task and valid, return webpack supplied smallest
  @if ($feConfigSmallestBreakpoint and map-get($breakpoints-with-directions, $feConfigSmallestBreakpoint)) {
    @return $feConfigSmallestBreakpoint;
  }

  // else look for the smallest
  $smallestName: null;
  $minSize: 999999999999px;

  @each $key, $value in $points {
    @if $value < $minSize {
      $minSize: $value;
      $smallestName: $key;
    }
  }
  @debug $smallestName;
  @return $smallestName;
}
