@mixin setBreakpointColumn($index, $breakpoint: null) {
  @if ($breakpoint) {
    .col-#{$index}\@#{$breakpoint} {
      grid-column: span #{$index};
    }
    .colspan-#{$index}\@#{$breakpoint} {
      width: colspan($index);
    }
    .colspan-#{$index}\@#{$breakpoint} > * {
      --grid-columns: #{$index};
    }
  } @else {
    @include setColumn($index);
  }
}
