:root {
  --nav-height: 88px;
  @include breakpoint('lg+') {
    --nav-height: 104px;
  }
}

.global-header--desktop {
  display: none;
  background-color: white;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;

  @include breakpoint('lg+') {
    display: block;
  }

  .skip-link {
    top: 12px;
    position: absolute;
    left: -9999px;
    z-index: 999;
    &:focus {
      left: 0;
    }
  }

  .skip-link-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .container {
      position: relative;
    }
  }

  .global-header__content {
    display: flex;
    height: var(--nav-height);
    align-items: center;
  }

  .global-header__logo {
    position: relative;
    z-index: 6;
    svg {
      height: 67px;
      width: 69px;
    }

    outline: none;
    &[data-focus-method='key']:focus {
      outline: none;
      @include textOutline;
    }
  }

  .nav__group__panel {
    visibility: hidden;
  }

  .global-header__nav,
  .nav {
    flex-grow: 1;
    display: flex;
    width: 100%;
  }

  .nav__items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav__primary-links {
    display: flex;
  }
  .nav__secondary-links {
    display: flex;
    position: relative;
    z-index: 6;
    li {
      margin-left: 32px;
    }
    a {
      @extend %f-ui-5;
    }
  }

  .nav__group__panel {
    display: block;
    min-height: 505px;
    position: absolute;
    background-color: white;
    width: 100%;
    left: -100%;
    top: var(--nav-height);
    z-index: 5;
    overflow: hidden;
    border-radius: 0 0 25px 25px;
    display: flex;
    padding-bottom: 64px;
    padding-top: 20px;
  }

  .nav__group__sections {
    background: white;
    @include container;
    display: flex;
  }

  .nav__group__section {
    @include colspan(3);
    @extend %f-ui-1;
    width: 100%;
    text-transform: uppercase;

    margin-left: var(--inner-gutter);
    &:first-child {
      margin-left: 0;
    }
    ul {
      display: block;
    }
  }

  .nav__group__section__links {
    border-top: 1px solid var(--border-primary);
    margin-top: 20px;
  }

  .nav__group__section__link {
    margin-top: 20px;
    a {
      @extend %f-ui-4;
      text-transform: none;
    }
  }

  .nav__group__section--feature {
    height: 100%;
  }

  .nav__group-button {
    &:hover {
      span {
        @include underline;
      }
    }
    &.is-open span {
      @include underline;
    }
  }

  .nav__link {
    &:hover {
      @include underline;
    }
    &.is-open {
      @include underline;
    }
  }

  .nav__group-button,
  .nav__link {
    @include buttonReset;
    @extend %f-ui-9;
    margin-left: 33px;
    position: relative;
    z-index: 6;

    &:hover {
      color: var(--text-text-interactive);
    }
    &[data-focus-method='key']:focus {
      @include textOutline;
    }
    svg {
      transition: 0.3s all;
    }

    &.is-open {
      color: var(--text-text-interactive);
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .search {
    @include buttonReset;
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    position: relative;
    top: 0px;
    &:hover {
      color: var(--text-text-interactive);
    }
    &[data-focus-method='key']:focus {
      @include textOutline;
    }
  }

  .search-panel {
    .search-panel__close {
      color: var(--text-secondary);
      top: -64px;
    }
  }
}

.desktop-feature {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
  outline: none;

  &:hover {
    @include expandFill;
  }
}

.desktop-feature__title {
  @extend %f-heading-2;
  padding: 20px 20px;
  text-transform: none;
}

.desktop-feature__description {
  @extend %f-body-1;
  text-transform: initial;
  padding: 0 20px;
}
.desktop-feature__content {
  margin-top: auto;
}

.desktop-feature__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .icon-button {
    margin-left: auto;
    flex-shrink: 0;
    align-self: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.desktop-feature__img-wrap {
  aspect-ratio: 1/1;
  @include colspan(2);
  background-color: var(--bg-secondary);
  border-radius: 4px;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.bg-blur {
  display: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(5px);
  z-index: 3;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(54, 70, 90, 0.5);
  }
}
