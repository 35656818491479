.form-label {
  @extend %f-ui-8;
  display: block;
  color: var(--text-primary);
}

.form-label__note {
  @extend %f-ui-5;
  color: var(--text-secondary);
}

.form-helper-text {
  @extend %f-ui-3;
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: var(--text-secondary);
}

.form-helper-text.form-helper-text--error {
  color: var(--text-error);
}

.form-helper-text svg {
  margin-right: 12px;
}

.form-input {
  margin-top: 12px;
}

.form-input--disabled {
  opacity: 0.3;
  pointer-events: none;
}

.form-input__field {
  position: relative;
}

.mktoAsterix {
  display: none !important;
}

.form-input__field input,
.form-input__field select {
  @extend %f-ui-5;
  -webkit-appearance: none;
  border: 0;
  display: block;
  width: 100%;
  margin-top: 12px;
  padding: 11px 20px 12px;
  border: none;
  border-radius: 4px 4px 0 0;
  background: var(--bg-secondary);
  color: var(--text-primary);
  outline: none;
  box-shadow: inset 0 -2px 0 -1px var(--border-interactive);
  transition: all 200ms ease-in-out;

  &:hover {
    border-color: var(--text-primary);
  }

  &:focus[data-focus-method='mouse'] {
    box-shadow: inset 0 -2px 0 0 var(--text-primary);
  }

  &:focus[data-focus-method='key'] {
    border-bottom-color: transparent;
    border-radius: 4px;
    box-shadow: inset 0 0 0 2px var(--text-primary);
  }

  &:disabled {
  }
}

.form-input__field select {
  background-repeat: no-repeat;
  background-position: center right 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 4L6 9L1 4' stroke='%2300172D' stroke-width='2' stroke-linejoin='round' /%3E%3C/svg%3E%0A");
  appearance: none;
}

.form-input__field svg {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 2;
  transform: translateY(-50%);
}

.form-input__field.form-input__field--error svg {
  color: var(--text-error);
}

.form-input__field.form-input__field--error input {
  padding-right: 60px;
  box-shadow: inset 0 -2px 0 0 var(--text-error);
}

.form-input__radio--inline,
.form-input__checkbox--inline {
  display: flex;
  flex-wrap: wrap;

  .form-input__radio,
  .form-input__checkbox {
    margin-right: 80px;
  }
}

.form-input__radio,
.form-input__checkbox {
  display: block;
  margin-top: 24px;

  &:first-child {
    margin-top: 20px;
  }
}
.form-input__chip input,
.form-input__radio input,
.form-input__checkbox input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.form-input__chip {
  display: inline-flex;
  margin-right: 20px;
  margin-top: 24px;
}

.radio-chip-group {
  margin-top: -24px;
  padding-top: 20px;
}
.form-input__chip-label {
  @extend %f-ui-5;
  display: block;
  padding: 10px 24px;
  border-radius: 64px;
  border: 1px solid var(--border-interactive);
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  svg {
    display: none;
  }
}

.form-input__chip
  input:focus[data-focus-method='key']
  + .form-input__chip-label {
  box-shadow: 0 0 0 2px var(--text-primary);
}

.form-input__chip input:checked + .form-input__chip-label {
  background-color: var(--bg-secondary);
  box-shadow: inset 0 0 0 1px var(--text-primary);
  svg {
    display: block;
    margin-right: 20px;
  }
}

.form-input__radio-label,
.form-input__checkbox-label {
  @extend %f-ui-5;
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  cursor: pointer;
}

.form-input__radio-label::before {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px var(--text-primary);
}
.form-input__radio-label span,
.form-input__checkbox-label span {
  border-bottom: 1px solid transparent;
}

.form-input__radio-label:hover span,
.form-input__checkbox-label:hover span {
  border-bottom-color: inherit;
}

.form-input__radio
  input:focus[data-focus-method='key']
  + .form-input__radio-label,
.form-input__checkbox
  input:focus[data-focus-method='key']
  + .form-input__checkbox-label {
  border-bottom-color: transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 2px var(--text-primary);
}

.form-input__radio input:checked + .form-input__radio-label::before {
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 6px,
    rgba(255, 255, 255, 0) 6px,
    rgba(255, 255, 255, 0) 18px,
    rgba(0, 0, 0, 1) 18px,
    rgba(0, 0, 0, 1) 100%
  );
  box-shadow: inset 0 0 0 2px var(--bg-inverse);
}

.form-input__checkbox-label .form-input__checkbox-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px var(--text-primary);

  svg {
    visibility: hidden;
  }

  .form-input__checkbox input:checked + & {
    background-color: var(--bg-inverse);
    color: var(--text-primary-inverse);
  }

  .form-input__checkbox input:checked + & svg {
    visibility: visible;
  }
}

.form-input__checkbox-indicator {
  flex-shrink: 0;
}

.form-upload__drop {
  display: block;
  margin-top: 16px;
  cursor: pointer;
}

.form-upload {
  label {
    overflow: hidden;
    position: relative;
  }
}

.form-upload__drop--hide {
  display: none;
}

.form-upload__notes {
  margin-top: 16px;

  p {
    margin-top: 4px;
  }
}

.form-upload__input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
}

.form-upload__selected {
  display: none;
  align-items: baseline;
  margin-top: 16px;
}

.form-upload__selected--show {
  display: flex;
}

.form-upload__selected-icon {
  display: none;
  align-self: center;
}

.form-upload__selected-icon--show {
  display: block;
}

.form-upload__selected-icon svg {
  display: block;
}

.form-upload__selected-name {
  @extend %f-ui-5;
  margin-left: 16px;
  margin-right: 16px;
}

.form-upload__selected-link {
}

.mktoForm {
  width: 100% !important;
  //   background: pink;
  float: none !important;

  .mktoFormCol {
    float: none !important;
    width: 100% !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .mktoHtmlText {
    margin-top: 24px !important;
    width: 100% !important;
    color: var(--text-secondary);

    a {
      color: var(--text-text-interactive);
    }
  }

  .mktoFieldWrap {
    width: 100% !important;
  }

  .mktoCheckboxList {
    input {
      height: 20px;
      width: 20px;
    }
  }

  .mktoRequiredField {
    label {
      &:after {
        content: ' (required)';
        color: var(--text-secondary);
        font-family: var(--aeo-400) !important;
        font-weight: 400 !important;
        font-size: 1.3125rem !important;
        letter-spacing: 0.01em !important;
      }
    }
  }

  .mktoOffset,
  .mktoGutter {
    display: none !important;
  }

  .indicatesRequiredFields {
    display: none !important;
  }

  .mktoLabel {
    margin-top: 24px !important;
    display: block !important;
    padding-top: 0 !important;
    float: none !important;
    width: 100% !important;
    font-family: var(--aeo-500) !important;
    font-weight: 500 !important;
    font-size: 1.3125rem !important;
    letter-spacing: 0.01em !important;
    line-height: 120% !important;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
  }

  .mktoTextField,
  .mktoTelField,
  .mktoEmailField,
  select {
    display: block;
    background-color: pink;
    font-family: var(--aeo-400) !important;
    font-weight: 400 !important;
    font-size: 1.3125rem !important;
    letter-spacing: 0.01em !important;
    line-height: 120% !important;
    height: 48px !important;
    -webkit-appearance: none !important;
    border: 0 !important;
    display: block !important;
    width: 100% !important;
    margin-top: 12px !important;
    padding: 11px 20px 12px !important;
    border-radius: 4px 4px 0 0 !important;
    background: var(--bg-secondary) !important;
    color: var(--text-primary) !important;
    outline: none !important;
    box-shadow: inset 0 -2px 0 -1px var(--border-interactive) !important;
    transition: all 200ms ease-in-out !important;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
  }

  textarea {
    margin-top: 12px !important;
    font-family: var(--aeo-500) !important;
    font-weight: 500 !important;
    font-size: 1.3125rem !important;
    letter-spacing: 0.01em !important;
    line-height: 120% !important;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
    -webkit-appearance: none !important;
    border: none !important;
    outline: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    padding: 11px 20px !important;
    width: 100% !important;

    box-shadow: inset 0 -2px 0 -1px var(--border-interactive);
    background-color: var(--bg-secondary);
    min-height: 200px;
  }

  select {
    background-repeat: no-repeat !important;
    background-position: center right 20px !important;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 4L6 9L1 4' stroke='%2300172D' stroke-width='2' stroke-linejoin='round' /%3E%3C/svg%3E%0A") !important;
    appearance: none !important;
  }

  .mktoButtonWrap {
    margin-left: 0 !important;
    margin-top: 12px !important;
  }

  .mktoButtonRow {
    margin-top: 12px !important;
  }

  .mktoFormRow {
  }

  button.mktoButton,
  button {
    margin-top: 24px !important;
    border: none !important;
    color: var(--white) !important;
    background: var(--red-90) !important;
    transition: color 0.3s, background 0.3s !important;
    position: relative !important;
    display: inline-flex !important;
    padding: 10px 32px 13px !important;
    border-radius: 48px !important;
    font-family: var(--aeo-400) !important;
    font-weight: 400 !important;
    font-size: 21px !important;
    letter-spacing: 0.01em !important;
    line-height: 120% !important;

    &:hover {
      border: none !important;
      background: var(--red-85) !important;
    }

    &:active {
      border: none !important;
      background: var(--red-80) !important;
    }
  }
}
