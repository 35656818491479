.editorial-card {
  overflow: hidden;
  text-decoration: none;
  color: var(--text-primary);
  &:hover,
  &:focus {
    outline: none;
    .editorial-card__title span {
      color: var(--text-text-interactive);
      @include underline;
    }
  }
}

.editorial-card__title {
  @extend %f-heading-2;
  margin-top: 8px;
  @include breakpoint('md+') {
    margin-top: 12px;
  }
}

.editorial-card__label {
  @extend %f-ui-1;
  text-transform: uppercase;
  @include breakpoint('md+') {
    margin-top: 20px;
  }
}
.editorial-card__description {
  @extend %f-body-1;
  margin-top: 12px;
}
.editorial-card--small {
  @include breakpoint('sm+') {
    display: flex;
    justify-content: space-between;
  }
  @include breakpoint('md+') {
    flex-direction: column;
    justify-content: flex-start;
    @include colspan(6);
  }
  @include breakpoint('lg+') {
    @include colspan(3);
  }
  .editorial-card__inline-media {
    display: block;
    float: right;
    width: 100px;
    overflow: hidden;
    border-radius: 8px;
    aspect-ratio: 1/1;
    width: 98px;
    margin-bottom: 16px;
    margin-left: 20px;
    @include breakpoint('sm+') {
      display: none;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .editorial-card__title {
    @extend %f-heading-1;
  }

  .editorial-card__media {
    display: none;
    @include breakpoint('sm+') {
      @include colspan(4);
      display: block;
      aspect-ratio: 1/1;
      order: 1;
    }
    @include breakpoint('md+') {
      @include colspan(6);
    }
    @include breakpoint('lg+') {
      @include colspan(3);
    }
  }

  .editorial-card__content {
    @include breakpoint('sm+') {
      @include colspan(8);
    }
    @include breakpoint('md+') {
      @include colspan(6);
      order: 2;
    }
    @include breakpoint('lg+') {
      @include colspan(3);
      padding-right: 20px;
    }
  }
}

.editorial-card__inline-media {
  display: none;
}

.editorial-card__media {
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.editorial-card--feature {
  display: flex;
  flex-direction: column;

  @include colspan(12);
  @include breakpoint('lg+') {
    @include colspan(6);
  }

  .editorial-card__label {
    margin-top: 20px;
  }

  .editorial-card__description,
  .editorial-card__title {
    @include breakpoint('lg+') {
      padding-right: 20px;
    }
  }

  .editorial-card__media {
    aspect-ratio: 8/5;
    order: 1;
  }
  .editorial-card__content {
    order: 2;
  }
  .editorial-card__description {
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
    }
  }
  .editorial-card__title {
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
    }
  }
}

.editorial-card--basic {
  display: flex;
  //   flex-direction: column;
  flex-direction: row;
  justify-content: space-between;
  @include breakpoint('sm+') {
    flex-direction: row;
    justify-content: space-between;
  }
  @include breakpoint('md+') {
    flex-direction: column;
  }
  .editorial-card__media {
    aspect-ratio: 8/5;
    order: 1;
    display: none;
    @include breakpoint('sm+') {
      flex-shrink: 0;
      display: block;
      aspect-ratio: 1/1;
      @include colspan(4);
    }
    @include breakpoint('md+') {
      width: 100%;
      aspect-ratio: 8/5;
    }
  }
  .editorial-card__content {
    @include breakpoint('sm+') {
      @include colspan(8);
    }
    @include breakpoint('md+') {
      order: 2;
      width: 100%;
    }
  }

  .editorial-card__inline-media {
    display: block;
    float: right;
    width: 100px;
    overflow: hidden;
    border-radius: 8px;
    aspect-ratio: 1/1;
    width: 98px;
    margin-bottom: 16px;
    margin-left: 20px;
    @include breakpoint('sm+') {
      display: none;
    }
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .editorial-card__title {
    @extend %f-heading-1;
    @include breakpoint('md+') {
      padding-right: 20px;
    }
  }

  .editorial-card__label {
    margin-top: 0;
    @include breakpoint('sm+') {
    }
    @include breakpoint('md+') {
      margin-top: 20px;
      padding-right: 20px;
    }
  }

  .editorial-card__description {
    @include breakpoint('md+') {
      padding-right: 20px;
    }
  }
}
