@mixin outline {
  outline: none;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 48px;
    box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 4px black,
      0px 0px 0px 6px white;
  }
}

@mixin textOutline {
  outline: none;
  &:after {
    content: '';
    position: absolute;
    left: -1.5px;
    top: -1.5px;
    height: calc(100% + 3px);
    width: calc(100% + 3px);
    border-radius: 3px;
    box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 4px black,
      0px 0px 0px 6px white;
  }
}
