.author-card {
  padding: 0px;
  position: relative;
  color: var(--text-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint('md+') {
    flex-direction: row;
  }
}

.author-card__primary {
  @include colspan(6);
  flex-shrink: 0;
  @include breakpoint('md+') {
    @include colspan(3);
  }
  @include breakpoint('lg+') {
    @include colspan(2);
  }
}

.author-card__image {
  border-radius: 100%;
  overflow: hidden;
  @include aspectRatio(1, 1);
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.author-card__secondary {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  @include breakpoint('md+') {
    margin-top: 0px;
    margin-left: 20px;
  }
}

.author-card__name {
  @extend %f-ui-7;
}

.author-card__title {
  @extend %f-ui-7;
  color: var(--text-secondary);
}

.author-card__tagline {
  @extend %f-body-1;
  margin-top: 12px;
}

.author-card__connect {
  //   @extend %f-ui-5;
  margin-top: 24px;
  //   display: flex;
  //   flex-direction: row;

  //   a {
  //     color: var(--text-primary);
  //     text-decoration: none;
  //     background-image: linear-gradient(
  //       to bottom,
  //       var(--text-primary) 75%,
  //       var(--text-primary) 75%
  //     );
  //     background-repeat: repeat-x;
  //     background-size: 1px 2px;
  //     background-position: 0 98%;
  //   }
}

.author-card__connect__icon {
  //   margin-right: 12px;
  //   position: relative;
  //   top: 2px;
}
