@mixin gridContainer {
  display: grid;
  column-gap: var(--inner-gutter);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
}

@mixin gridColSpan($cols) {
  --grid-columns: #{$cols};
  grid-column: span #{$cols};
}
