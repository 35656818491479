.report{
	&__overlay--show{
		@include breakpoint('lg+') {
		opacity:1!important;
		display:block!important;
		}
	}
	&__nav--main{
		z-index:4;
	}
	&--overflow{
		overflow:hidden!important;
	}
	&__nav{
		position:fixed;
		top:72px;
		left:0;
		width:100%;
		z-index:3;
		@include breakpoint('lg+') {
			top:89px;
		}
		.nav__group__section > * {
			--grid-columns: 3;
		}
		&--fixed{
			position:fixed;
		}
		&__container{
			position:Relative;
			background:#ffffff;
			padding:1rem 0;
		}
		&__btn{
			display:flex;
			position:relative;
			align-items:center;
			border:none;
			background-color:transparent;
			padding:0;
			font-size:14px;
			font-family:'AeonikPro-Regular', Helvetica, Arial, Sans-Serif;
			height:24px;
			color:#000000;
			cursor:pointer;
			p{
				text-transform:uppercase;
				display:inline-block;
				margin-left:2.25rem;
				color:#000000;
			}
		}
		&__panel{
			visibility:hidden;
			opacity:0;
			height:1px;
			width:100%;
			position:absolute;
			left:0;
			top:50px;
			background:#ffffff;
			z-index:100;
			transition:all 0.2s ease-in-out;
		}
		&--show{
			visibility:visible;
			height:100%;
			opacity:1;
			transition:all 0.2s ease-in-out;
		}
		&__icon{
			position:absolute;
			top:0;
			left:0;
			transition:all 0.2s ease-in-out;
		}
		&__close{
			opacity:0;
			z-index:-1;
		}
		&__open{
			opacity:1;
			z-index:1;
		}
		&__icon--show{
			opacity:1;
			z-index:1;
			transition:all 0.2s ease-in-out;
		}
		&__icon--hide{
			opacity:0;
			z-index:-1;
			transition:all 0.2s ease-in-out;
		}
		&__group{
			background:#ffffff;
			overflow-y:scroll;
			height: calc(100vh - 120px);
			@include breakpoint('lg+') {
				overflow: auto;
				height:auto;
			}
		}
		&__content{
			display:grid;
			padding:1rem 0 2.5rem;
			background:#ffffff;
			gap:20px;
			grid-template-rows: repeat(4,1fr);
			@include breakpoint('md+') {
				gap:20px;
				grid-template-columns: repeat(2,1fr);
				grid-template-rows: unset;
			}
			@include breakpoint('lg+') {
				gap:35px;
				grid-template-columns: repeat(4,1fr);
			}
			.desktop-feature__img-wrap{
				margin-right:1rem;
				display:none;
				@include breakpoint('lg+') {
					display:block;
				}
				img{
					width:100%;
				}
			}
			.desktop-feature__content{
				display:flex;
				margin-bottom:1rem;
				@include breakpoint('lg+') {
					display:block;
					margin-bottom:0;				}
			}
			.desktop-feature__description{
				flex-grow:1;
			}
		}
	}
	&__hero{
		margin-top:8.5rem;
		@include breakpoint('lg+') {
			margin-top:10rem;
			}
		.hero-feature__subtitle{
			margin-top:0;
		}
		.hero-feature__color-wrap{
			padding-top:0;
		}
		.hero-feature__heading h1{
			max-width:1163px;
		}
		&--margin-sm{
			margin-top:4rem;
			@include breakpoint('lg+') {
				margin-top:4rem;
			}
		}
	}
	&__grid--2{
		display:grid;
		gap:35px;
		@include breakpoint('md+') {
		grid-template-columns:repeat(2, 1fr);
		}
	}
	&__numbers{
		margin-top:85px;
		    width: calc(((12 / var(--grid-columns)) * var(--max-width, 100%)) - (var(--inner-gutter) - (12 / var(--grid-columns) * var(--inner-gutter))));
		@include breakpoint('md+') {
		    width:  calc(((6 / var(--grid-columns)) * var(--max-width, 100%)) - (var(--inner-gutter) - (6 / var(--grid-columns) * var(--inner-gutter))));
		}
		@include breakpoint('xl+') {
			margin-top:155px;
		}
		.report__grid--2{
			grid-template-columns: repeat(2, 1fr);
		}
		&--align{
			display:flex;
			justify-content: flex-end;
		}
		&__item{
			margin-bottom:2rem;
			h3{
				font-family:var(--aeo-400);
				font-weight:600;
				margin-bottom:1rem;
			}
		}
	}
	&__image-text{
		margin-top:20px;
		&__img{
			border-radius: 8px;
			overflow: hidden;
			}
		&__content{
			.f-heading-5{
				margin-bottom:2rem;
			}
			.button{
				margin-top:64px;
			}
			.editorial-card__label {
				margin-bottom:0.5rem;
			}
		} 
	}
	&__quote{
		&__heading{
			margin-bottom:2rem;
			margin-top:0.5rem;
			@include breakpoint('lg+') {
				margin-bottom:6rem;
			}
		}
		&-mark{
			margin-bottom:0.5rem;
		}
		&__title{
			display: block;
			color: var(--text-secondary);
		}
		&__profile{
			display:flex;
			
		}
		&__image__container{
			width: calc(((5 / var(--grid-columns)) * var(--max-width, 100%)) - (var(--inner-gutter) - (5 / var(--grid-columns) * var(--inner-gutter))));
			flex-shrink: 0;
			@include breakpoint('lg+') {
				width: calc(((4 / var(--grid-columns)) * var(--max-width, 100%)) - (var(--inner-gutter) - (4 / var(--grid-columns) * var(--inner-gutter))));
			}
		}
		&__image{
			border-radius: 100%;
			overflow: hidden;
			position: relative;
			img{
				display: block;
				height: 100%;
				width: 100%;
				   object-fit: cover;
			}
		}
		&__cite{
			margin-left:20px;
			margin-top:5px;
		}
		&--margin{
			margin-top:64px;
		}
		&__text--margin{
			margin-bottom:64px;
		}
	}
	&__author{
		&__image{
			width: 64px;
			height:64px;
			@include breakpoint('lg+') {
				width: 70px;
				height:70px;
			}
		}
		&__container{
			flex-direction:row;
			flex-wrap:wrap;
			@include breakpoint('md+') {
			flex-direction:column;
			flex-wrap:nowrap;
			}
		}
		&__row{
			display:flex;
			margin-right:1.5rem;
			margin-bottom:1rem;
			@include breakpoint('md+') {
			margin-right:0;
			}

		}
	}
	&__card{
		&__media--download{
			img{
				width:100%;
			}

		}
		.card__description{
			p{
				margin-top:24px;
			}
			p:first-of-type{
				margin-top:0;
			}
		}
		.editorial-card__label{
			padding: 0 20px;
			margin-top: 16px;
			@include breakpoint('lg+') {
				padding: 0;
			}
		}
		&__subtitle{
			color:var(--text-secondary);
			display:block;
			margin-top:0.25rem;
		}
	}
	&__anchor__link{
		&:target{
			scroll-margin-top: 300px;
		}
	}
	&__anchors{
		margin-top:1.5rem;
		display:flex;
		flex-wrap:wrap;
		flex-direction:column;
		@include breakpoint('md+') {
			flex-direction:row;
		}
		&__block{
			display:none;
			padding-top: 96px;

			@include breakpoint('md+') {
				display:block;
			}
			@include breakpoint('lg+') {
				padding-top: 128px;
			}
			@include breakpoint('xl+') {
				margin-top:32px;
				padding-top: 160px;
			}
			.section-title__content{
				border-bottom:1px solid var(--border-primary);
				padding-bottom:10px;
			}
			
		}
		&__container{
			background:white;
			width:100%;
			z-index:1;
		}
		
		li{
			margin-bottom:0.75rem;
			display:flex;
			text-align:center;
			width:100%;
			@include breakpoint('md+') {
				margin-right:0.75rem;
				width:auto;
			}
			@include breakpoint('xl+') {
				margin-right:1rem;
				margin-bottom:1rem;
			}
			a{
				border:solid 1px var(--grey-60);
				font-family: var(--aeo-400);
				color: var(--navy-20);
				border-radius:64px;
				padding:0.75rem 1.25rem;
				text-decoration: none;
				font-size:16px;
				transition: color 0.2s ease-in;
				width:100%;
				@include breakpoint('xl+') {
					padding:1rem 1.5rem;
					font-size:18px;
				}
			}
			&.active a{
				background:var(--navy-20);
				color:white;
				transition: color 0.2s ease-in;
			}
		}
		&__scroll{
			position:fixed;
			top:128px;
			@include breakpoint('lg+') {
				top:145px;
			}
			.section-title{
				margin-top:0;
			}
		}
	}
	&__caption{
		&__heading{
			font-family: var(--aeo-400);
			color:var(--navy-20);
			font-weight:700;
			font-size:21px;
			margin-bottom:0.75rem;
		}
	}
	&__signature{
		width:150px!important; 
		margin-left:-1.5rem;
		height:auto!important;
	}
	&--border{
		border-top:none;
	}
	&--margin-top{
		margin-top:20px;
	}
	&--margin-intro{
		margin-top:44px;
	}
	&__section--margin{
		margin-top:40px;
	}
	&__heading--400{
		font-weight:400;
	}
	&--margin-none{
		margin-top:0;
	}


} 