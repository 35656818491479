.moment-stat {
  background: var(--aqua-95);
  color: var(--aqua-45);
  border-radius: 8px;
  padding: 24px 20px 20px 20px;
  display: block;
  text-decoration: none;
  @include colspan(12);

  @include breakpoint('md+') {
    @include colspan(6);
  }

  @include breakpoint('xl+') {
    display: flex;
    flex-direction: column;
  }

  &:hover,
  &:focus {
    @include expandFill;
  }
}

.moment-stat__eyebrow {
  @extend %f-ui-1;
  text-transform: uppercase;
  padding-bottom: 12px;
  display: block;
  display: none;
}

.moment-stat__stat {
  @extend %f-numeral;
}

.moment-stat__subhead {
  margin-top: 12px;
  @extend %f-subhead-2;
}

.moment-stat__secondary {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  align-items: center;

  @include breakpoint('xl+') {
    margin-top: auto;
  }

  .icon-button {
    align-self: end;
  }
}

.moment-stat__cta {
  @extend %f-ui-4;
}

.moment-stat__wrapper {
  @include breakpoint('md+') {
    display: flex;
  }
}
