.special-code {
  @include smallSpacing;
  @include breakpoint('md+') {
    @include colspan(10);
  }
  @include breakpoint('lg+') {
    @include colspan(6);
    margin-left: auto;
  }
}

.special-code--full-width {
  width: 100%;
}

