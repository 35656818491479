.quote-carousel {
  @include spacing;

  @include breakpoint('md+') {
    display: flex;
    justify-content: space-between;
  }

  .swiper {
    margin: 0;
  }
}

.quote-slider-1 {
  position: relative;
  @include breakpoint('md+') {
    @include colspan(6);
  }

  img {
    display: block;
    width: 100%;
  }

  .media-slider-wrap {
    position: relative;
  }

  .swiper-button-prev {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @include breakpoint('md-') {
      display: none;
    }
  }

  .swiper-button-next {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @include breakpoint('md-') {
      display: none;
    }
  }
}

.quote-slider-1__nav {
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 56px;
  padding: 4px 8px;
  bottom: 20px;
  display: flex;
  button {
    @include buttonReset;
    position: relative;
    padding: 0;
    margin-left: 12px;

    border-radius: 100%;
    img {
      width: calc(100%);
    }
    .avatar-inner {
      overflow: hidden;
      height: 40px;
      border-radius: 100%;
      width: 40px;
    }
    &:first-child {
      margin-left: 0;
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 48px;
        box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 2px #64778e,
          0px 0px 0px 4px white;
      }
    }

    &.is-active {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 48px;
        box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 2px black,
          0px 0px 0px 4px white;
      }
    }

    &[data-focus-method='key']:focus {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-radius: 48px;
        box-shadow: inset 0px 0px 0px 2px white, 0px 0px 0px 4px black,
          0px 0px 0px 4px white;
      }
    }
  }
}

.quote-slide__media {
  @include imageBorder;
}

.swiper-button-disabled {
  button {
    opacity: 0.5;
    pointer-events: none;
    &:after {
      display: none !important;
    }
  }
}

.media-slider-wrap {
  position: relative;
  @include breakpoint('md+') {
    @include colspan(6);
    margin-top: 0px;
    height: 100%;
  }
}

.quote-slider-wrap {
  position: relative;
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  height: auto;
  @include breakpoint('md+') {
    @include colspan(6);
    margin-top: 0px;
  }
  @include breakpoint('lg+') {
  }

  @include breakpoint('lg+') {
    padding: 0 20px;
  }
}

.quote-slider-2 {
  flex-grow: 1;
  &.swiper {
    margin-top: 17px;
  }
}

.quote-slide {
  @include breakpoint('md+') {
    display: flex;
    flex-direction: column;
  }

  blockquote {
    @extend %f-subhead-3;
  }

  svg {
    display: block;
    padding-bottom: 17px;
  }

  figcaption {
    margin-top: 32px;
    @include breakpoint('md+') {
      margin-top: 40px;
    }
  }

  .quote-slide__byline {
    @extend %f-ui-5;
    display: block;
  }

  .quote-slide__title {
    @extend %f-ui-5;
    display: block;
    color: var(--text-secondary);
  }
}

.quote-slide__link {
  margin-top: 32px;
  @include breakpoint('lg+') {
    margin-top: 40px;
  }
}
