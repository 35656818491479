.standfirst {
  @extend %f-subhead-3;
  margin-top: 80px;
  @include breakpoint('lg+') {
    width: 100%;
    margin-top: 20px;
    display: flex;
  }
}

.standfirst__content {
  @include breakpoint('md+') {
    @include colspan(10);
  }
  @include breakpoint('lg+') {
    @include colspan(6);
    padding-right: 20px;
    margin-left: auto;
  }

  strong {
    @include richTextBold;
  }

  a {
    text-decoration: none;
    position: relative;
    color: var(--text-text-interactive);
    @include underline;
    &[data-focus-method='key']:focus {
      @include textOutline;
    }
  }
}
