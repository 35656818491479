.hero-editorial {
  //   @include spacing;
  margin-top: 40px;
  @include breakpoint('md+') {
    margin-top: 64px;
  }
  @include breakpoint('lg+') {
    margin-top: 96px;
  }
  .container {
    position: relative;

    @include breakpoint('lg+') {
      padding-bottom: 0;
    }
  }
}

.hero-editorial--with-image,
.hero-editorial--job-detail {
  margin-top: 20px;
}

.hero-editorial__inner {
  padding-bottom: 80px;
  border-bottom: 1px solid var(--border-primary);

  @include breakpoint('lg+') {
    padding-bottom: 128px;
  }
}

.hero-editorial__media {
  @include imageBorder;
  aspect-ratio: 1/1;
  @include breakpoint('md+') {
    aspect-ratio: 2/1;
  }
  img {
    object-fit: cover;
    height: 100%;
  }
}

.hero-editorial__label {
  @extend %f-ui-1;
  display: block;
  margin-top: 40px;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.hero-editorial__title,
.hero-editorial__subtitle {
  @extend %f-heading-6;

  @include breakpoint('md+') {
    @include colspan(10);
  }
}

.hero-editorial__subtitle {
  color: var(--text-secondary);
}

.hero-editorial__meta {
  @extend %f-ui-4;
  margin-top: 32px;

  @include breakpoint('lg+') {
    margin-top: 48px;
  }
}

.hero-editorial__avatars {
  display: flex;

  img {
    width: 64px;
    height: 64px;
    margin-right: 12px;
    border-radius: 100%;
  }
}

.hero-editorial__authors,
.hero-editorial__job-address {
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
}

.hero-editorial__date,
.hero-editorial__publication-name,
.hero-editorial__job-role {
  margin-top: 4px;
  color: var(--text-secondary);

  &:first-child {
    margin-top: 0;
  }

  .hero-editorial__avatars + & {
    margin-top: 12px;
  }
}

.hero-editorial__rail {
  margin-top: 40px;

  @include breakpoint('lg+') {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 28px;
  }
}

.hero-editorial__rail-title {
  @extend %f-ui-1;
  text-transform: uppercase;
}

.hero-editorial__tags {
  margin-top: 20px;
  display: none;
  li {
    margin-top: 12px;
  }
  @media screen and (min-width: 1022px) {
    display: block;
  }
}

.hero-editorial__share {
  margin-top: 40px;
}

.hero-editorial__rail > div:nth-child(1):not(.hero-editorial__btn) {
  margin-top: 0;
}

#heroEditorialTagsList{
  display: none;
  @media screen and (min-width: 1022px) {
    display: block;
  }
}
