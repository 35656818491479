.image-module {
  img {
    display: block;
    width: 100%;
  }
}

.image-module__content {
  margin-top: 16px;
  span {
    display: block;
  }
  .image-module__title {
    @extend %f-ed-heading-1;
    padding-bottom: 8px;
  }

  .image-module__description {
    @extend %f-caption;
    color: var(--text-secondary);
  }
}

.image-module__wrapper {
  @include imageBorder;
}
