.card {
  @include imageBorder;
  display: block;
  background: var(--bg-secondary);
  color: var(--text-primary);
  text-decoration: none;
  outline: none;

  &:hover,
  &:focus {
    @include expandFill;
  }

  .card__title {
    @extend %f-heading-3;
    padding: 0 20px;
    margin-top: 16px;
  }

  .card__content {
    background: var(--bg-secondary);
  }

  .card__description {
    margin-top: 20px;
    padding: 0 20px;
  }

  .card__cta {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    .icon-button {
      flex-shrink: 0;
      align-self: flex-end;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .card__media {
    aspect-ratio: 8/5;
    background: var(--bg-secondary);
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

.card-envelope {
}

.card-basic {
  @include breakpoint('md+') {
    @include colspan(6);
  }
  @include breakpoint('md+') {
    @include colspan(6);
  }

  & + a {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    right: 0;
    left: 0;
  }

  .list--2 & {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
  }

  .card__description {
    @include breakpoint('lg+') {
      @include colspan(5);
    }
  }

  // .card__cta {
  //   @include breakpoint('md+') {
  //     margin-top: 60px;
  //   }
  //   @include breakpoint('lg+') {
  //     margin-top: 100px;
  //   }
  // }
}

.card-feature {
  @include breakpoint('lg+') {
    background: var(--bg-secondary);
    display: flex;
    justify-content: space-between;
  }

  .card__content {
    @include breakpoint('lg+') {
      display: flex;
      flex-direction: column;
      @include colspan(6);
    }
  }
  .card__cta {
    @include breakpoint('md+') {
      margin-top: 60px;
    }
    @include breakpoint('lg+') {
      margin-top: auto;
    }
  }

  .card__media {
    @include breakpoint('lg+') {
      @include colspan(6);
    }
  }

  .card__description,
  .card__title {
    @include breakpoint('lg+') {
      padding-left: 0;
    }
  }

  .card__description {
    @include breakpoint('md+') {
      width: colspan(10);
    }
    @include breakpoint('lg+') {
      width: colspan(5);
    }
  }
}

.card-feature--inverse {
  .card__content {
    @include breakpoint('lg+') {
      order: 1;
    }
  }
  .card__media {
    @include breakpoint('lg+') {
      order: 2;
    }
  }
  .card__description,
  .card__title {
    @include breakpoint('lg+') {
      padding-left: 20px;
    }
  }
}

.card-envelope {
  @include breakpoint('md+') {
    @include colspan(6);
  }
  .card-envelope__header {
    display: flex;
    justify-content: space-between;
  }
  .card__media {
    height: 96px;
    width: 96px;
    aspect-ratio: 1/1;
    margin-left: 20px;
    overflow: hidden;
    flex-shrink: 0;
    @include breakpoint('sm+') {
      @include colspan(4);
      height: auto;
    }
    @include breakpoint('md+') {
      @include colspan(2);
    }
  }

  @include imageBorder;
  height: 100%;
  justify-content: space-between;

  .card__title {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
    @include breakpoint('md+') {
      width: 100%;
      padding-left: 20px;
      padding-right: 40px;
    }
    width: 100%;
    @include breakpoint('lg+') {
      @include colspan(4);
    }
  }

  .icon-button {
    flex-shrink: 0;
    align-self: flex-end;
  }

  .card__media {
    @include imageBorder;
  }

  .card__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card__cta {
    margin-top: auto;
    padding-top: 20px;
    .icon-button {
      margin-left: auto;
    }
  }

  .card__description {
    @include breakpoint('lg+') {
      @include colspan(5);
    }
    @include breakpoint('xl+') {
      @include colspan(4);
    }
  }
}

.card-generic__title {
  @extend %f-heading-2;
  margin-top: 20px;
}

.card-generic__description {
  @extend %f-body-1;
  margin-top: 20px;

  p {
    margin-top: 24px;
  }

  a {
    color: var(--text-text-interactive);
  }
}

.card-generic__media {
  @include imageBorder;
}
