.moment-quote {
  background: var(--navy-20);
  color: white;
  border-radius: 8px;
  padding: 24px 20px 20px 20px;
  display: block;
  text-decoration: none;
  @include breakpoint('md+') {
    @include colspan(6);
  }
  @include breakpoint('xl+') {
    display: flex;
    flex-direction: column;
  }
}

a.moment-quote {
  &:hover,
  &:focus {
    @include expandFill;
  }
}

.moment-quote__secondary {
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
  align-items: center;

  // grid layout.js generates a min-height for the element
  @include breakpoint('xl+') {
    margin-top: auto;
  }

  .icon-button {
    align-self: end;
  }
}

.moment-quote__heading {
  @extend %f-subhead-3;
  position: relative;
  &:before {
    @extend %f-subhead-3;
    content: '“';
    position: absolute;
    top: 0px;
    left: -9px;
    @include breakpoint('md+') {
      left: -9px;
    }
    @include breakpoint('xl+') {
      left: -12px;
    }
    @include breakpoint('xxxl+') {
      left: -13px;
    }
  }
}

.moment-quote__eyebrow {
  @extend %f-ui-1;
  text-transform: uppercase;
  padding-bottom: 16px;
  display: block;
  display: none;
}

.author-block {
  padding-top: 20px;
  display: flex;
  justify-content: left;
  @include breakpoint('lg+') {
    margin-top: 24px;
  }
}

.author-block__image {
  border-radius: 100%;
  height: 80px;
  width: 80px;
  overflow: hidden;
  margin-right: 16px;
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include breakpoint('lg+') {
    height: 96px;
    width: 96px;
  }
}

.author-block__byline {
  @extend %f-ui-4;
  margin-top: 16px;
  display: block;
}

.author-block__title {
  @extend %f-ui-4;
  display: block;
  color: var(--grey-80);
}

.cta {
  @extend %f-ui-4;
  color: white;
}

.lower-block {
  display: flex;
  justify-content: space-between;
}
