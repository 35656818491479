.hero-landing-l2 {
  padding-top: 40px;
  @include breakpoint('md+') {
    padding-top: 64px;
  }
  @include breakpoint('lg+') {
    padding-top: 96px;
  }
}

.hero-landing-l2__eyebrow {
  @extend %f-ui-1;
  padding-bottom: 20px;
  text-transform: uppercase;
  display: flex;
  a {
    position: relative;
    display: flex;
    margin-left: 24px;
    &:before {
      content: '';
      height: 4px;
      width: 4px;
      border-radius: 100%;
      background-color: var(--border-primary);
      display: block;
      position: absolute;
      top: 5.5px;
      left: calc(-12px - 2px);
    }
    text-transform: uppercase;
    @extend %f-ui-1;
  }
}

.hero-landing-l2__title {
  border-top: 1px solid var(--grey-80);
  padding-top: 16px;

  @include breakpoint('lg+') {
    padding-top: 4px;
  }

  h1 {
    @extend %f-heading-7;
    @include breakpoint('md+') {
      @include colspan(10);
    }
  }
}

.hero-landing-l2__media {
  @include imageBorder;
  aspect-ratio: 1/1;
  overflow: hidden;

  margin-top: 40px;

  @include breakpoint('md+') {
    aspect-ratio: 2/1;
    margin-top: 64px;
  }

  @include breakpoint('lg+') {
    margin-top: 80px;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

.hero-landing-l2__intro {
  padding-top: 16px;
  margin-top: 20px;
  border-top: 1px solid var(--grey-80);
  flex-direction: column;

  @include breakpoint('lg+') {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }

  span {
    @extend %f-subhead-3;
    display: block;
    @include breakpoint('md+') {
      @include colspan(10);
    }
    @include breakpoint('lg+') {
      @include colspan(6);
      margin-left: auto;
      padding-right: 20px;
    }
  }
}

.hero-landing-l2__cta {
  margin-top: 40px;
  @include breakpoint('md+') {
    margin-top: 48px;
    @include colspan(6);
    margin-left: auto;
  }
}
