.search-filters {
  margin-top: 64px;
}

.filters {
  @include spacing;
}

.filters__list {
  @include gridContainer;
  margin-top: 20px;
  gap: 20px;
}

.filters__content {
  border-top: 1px solid var(--border-primary);
  padding-bottom: 40px;
}

.filters__item {
  @include gridColSpan(12);
  @include breakpoint('md+') {
    @include gridColSpan(4);
  }
  @include breakpoint('lg+') {
    @include gridColSpan(3);
  }
  .form-input {
    width: 100%;
  }
  label {
    @include hidden;
  }

  select,
  .form-input {
    margin-top: 0 !important;
  }
}

.filters__actions {
  margin-top: 40px;
  //display: flex;
  display: none;
  justify-content: space-between;
  width: 100%;
  @include breakpoint('md+') {
    //display: block;
    display: none;
  }

  button {
    @include colspan(6);
    justify-content: center;
    @include breakpoint('md+') {
      width: auto;
      margin-right: 20px;
    }
  }
}

.search-page-input {
  .search-input {
    @include breakpoint('md+') {
      @include colspan(6);
    }
  }
}
.filters__toggle {
  @include buttonReset;
  margin-bottom: 20px;
  border-radius: 48px;
  background-color: var(--bg-secondary);
  @extend %f-ui-5;
  display: inline-flex;
  align-items: center;
  //   width: 189px;
  padding-top: 10px;
  padding-bottom: 13px;
  padding-left: 22px;
  padding-right: 32px;
  justify-content: space-between;
  position: relative;
  svg {
    margin-right: 16px;
  }
  background-color: var(--bg-secondary);
  &:hover {
    background-color: var(--bg-tertiary);
  }
  &[data-focus-method='key']:focus {
    @include outline;
    background-color: var(--bg-tertiary);
  }
}

.filters__info {
  @extend %f-ui-5;
  border-top: 1px solid var(--border-primary);
  padding-top: 16px;
  @include breakpoint('md+') {
    display: flex;
    justify-content: space-between;
  }
}

.filters__inline-select {
  align-items: center;
  display: flex;
  margin-top: 24px;
  @include breakpoint('md+') {
    margin-top: 0;
  }
  span {
    @extend %f-ui-5;
  }
}

.filters__pagination {
  color: var(--text-secondary);
}

.dynamic-select {
  @extend %f-ui-5;
  position: relative;
  display: flex;
  justify-content: end;
  padding-right: 6px;
  --dynamic-size: 22px;
  --select-size: var(--dynamic-size);

  select::-ms-expand {
    display: none;
  }
  select {
    color: var(--text-primary);
    width: var(--select-size);
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 3px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    box-shadow: inset 0 -2px 0 -1px var(--border-interactive);
  }
  .select {
    margin-right: 6px;

    margin-left: 8px;
    width: auto;
    position: relative;
    cursor: pointer;
    z-index: 2;
  }

  svg {
    width: 10px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 1;
  }

  .helper-element {
    position: absolute;
    top: 0;
    left: -9999px;
  }
}
