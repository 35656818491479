/*
  @mixin hide-text

  Hides text in an element
*/
@mixin hide-text {
  color: transparent;
  font: 0 / 0 a; /* stylelint-disable-line */
  text-indent: -100%;
  text-shadow: none;
  overflow: hidden;
}
