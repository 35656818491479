@mixin body1Bold {
  font-family: 'AeonikPro-Medium';
  font-weight: 500;
  letter-spacing: 0.015em;
}

@mixin body2Bold {
  font-family: 'AeonikPro-Medium';
  font-weight: 500;
  letter-spacing: 0.015em;
  @include breakpoint('md+') {
    letter-spacing: 0.01em;
  }
}

@mixin body3Bold {
  font-family: 'AeonikPro-Medium';
  font-weight: 500;
  letter-spacing: 0.015em;
  @include breakpoint('md+') {
    letter-spacing: 0.01em;
  }
}

@mixin richTextBold {
  font-family: 'Orbikular-Regular';
  font-weight: 700;
  letter-spacing: 0.015em;
  @include breakpoint('md+') {
    letter-spacing: 0.01em;
  }
}
