.social-sharing {
}

.social-sharing__list {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.social-sharing__item {
  margin-right: 32px;
  button {
    color: var(--text-primary);
  }
  @include breakpoint('lg+') {
    margin-right: 24px;
  }

  button {
    padding: 0;
    border: 0;
    background: none;
  }
}
